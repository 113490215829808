@use '../../../app/styles/common/var';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  width: 100svw;
  background-color: var.$bg-header;
  z-index: 100;
  position: fixed;
  padding-inline: var.$padding_block_max;

  @media (max-width: 1600px) {
    padding-inline: var.$padding_block_lg;
  }
  @media (max-width: 1200px) {
    padding-inline: var.$padding_block_md;
  }
  @media (max-width: 768px) {
    padding-inline: var.$padding_block_tablet;
  }
  @media (max-width: 500px) {
    padding-inline: var.$padding_block_mobile;
  }
}

.headerLogo {
  position: relative;
  top: -3px;
}

.buttonMenu {
  @media (min-width: 1200px) {
    display: none !important;
  }
}