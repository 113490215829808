.root {
  max-width: 230px;
  max-height: 239px;
  min-height: 190px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 575px) {
    margin-bottom: 10px;
  }
  
  .add_file {
    width: 190px;
    height: 190px;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid var(--border, #eee);
    background: #fcfcfc;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .more_file {
    width: 80px;
    height: 80px;
  }
} 
.smallSize {
  width: 92px;
  height: 118px;
}

.addMediaContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  padding-top: 12px;
  cursor: pointer;
}

.addMediaText {
  color: #666666;
  font-weight: 600;
  font-size: 0.9rem;
  font-style: normal;
  line-height: 120%;
}