.root {
  padding: 250px 0;
  display: flex;
  justify-content: center;
  height: 100vh;
}

.container {
  max-width: 50px;
  position: relative;
}